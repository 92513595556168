
import React from "react";

interface IPendingCuttingTickets {
      ticketColor: string;
      description: string;
      total: number
}

const PendingCuttingTicket = ( props: IPendingCuttingTickets  ) =>  {

      return (
            <div className="station-total-entry" > 
                  <div className="station-total-label">{props.description}</div>
                  <div className="station-total zero-total"  style={{backgroundColor: props.ticketColor, color:"white" }}>{props.total}</div>
            </div>

      )
}

export default PendingCuttingTicket;