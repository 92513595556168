import React, { useEffect, useState } from "react"
import FIP from "../../models/fip";
import { Button } from 'primereact/button';
import Config from "../../config";
import useFetch from "../../hooks/use-fetch";
import LoginModal from "../login-modal";

interface IFrameModalFooterProps {
      frame: FIP
      orderNumber?: string;
      orderStatus?: string;
      ticketId: string;
      removeFromColumn: (stationId: number, frameSerialId: string) => void;
      isAuthenticated: boolean;
      setAuthenticated: any;
     
}
/**
 *  If the frame has a shipstation order on it, then link to shipstation
 *  If there is no shipstation link, then the 'ship' will just remove it from the FIP page
 *  and update it server side as 'shipped' so it moves to the shipped column. 
 */
interface OrderNameLookupResponse {
      success: boolean;
      orderNumber?: string;
      orderStatus?: string;
      error?: string;
}

const FrameModalFooter = (props: IFrameModalFooterProps) => {

      const [orderId, setOrderId] = useState<number | null>(null)
      const url = `${Config.baseUrl}/api/shipstation/order/lookup/${orderId}`;

      const [orderNumber, setOrderNumber] = useState<string | null>(null)
      const [orderStatus, setOrderStatus] = useState<string | null>(null)
      const { data, loading, error } = useFetch<OrderNameLookupResponse>( orderId ? url : "", [], {});

      const [showLogin, setShowLogin] = useState<boolean>( false );

      useEffect(() => {
            if (data && data.success === true) {
                  setOrderNumber(data.orderNumber!)
                  setOrderStatus(data.orderStatus!)
            }

      }, [data]);

      useEffect(() => {
            if (props.frame && props.frame.ticket && props.frame.ticket!.ssOrderId) {
                  setOrderId(props.frame.ticket!.ssOrderId!)
            }

      }, [props.frame]);

      function logout(){
           
            fetch (`${Config.baseUrl}/api/auth/logout/web`, {
                  method: 'POST',
                  headers: { "Content-Type": "application/json" },
                 mode: "cors",
                 credentials: 'include'
             })
             .then((response) => response.json())
             .then((result) => {

                        props.setAuthenticated( false );
              });

      }
      function markFrameAsShipped(){
            fetch (`${Config.baseUrl}/api/shipstation/ticket/ship-frame-web`, {
                  method: 'PUT',
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    ticketId: props.ticketId,
                 }),

                 mode: "cors",
                 credentials: 'include'
             })
             .then((response) => response.json())
             .then((result) => {
                 if(result.success === true){
                        alert("saved")
                  } else {
                      alert('not authorized');
                  }
               });
      }

      //api/shipstation/order/lookup/:orderId'
      return (
      <>
      { showLogin && 
                  
                  <LoginModal visible={showLogin} 
                              closeModal={() => { setShowLogin(false );}}
                              isAuthenticated={ props.isAuthenticated }
                              setAuthenticated={ props.setAuthenticated}
                  ></LoginModal>

      }
      { !props.isAuthenticated &&
            <div className='please-login-notification'>
                  <div> Please login to modify frames </div>
                  <Button label='Login' onClick={()=> setShowLogin(true )}/>
            </div>
      }
      { props.isAuthenticated &&
            <div className="frame-modal-footerwrapper">

                  {props.frame?.ticket?.ssOrderId && loading &&
                        <>
                              <div> Looking up order name in shipstation....</div>
                        </>
                  }

                  {orderNumber && orderStatus === 'awaiting_shipment' &&
                        <div className="card flex justify-content-center">

                              <a href={`https://ship4.shipstation.com/orders/awaiting-shipment?quickSearch=${orderNumber}`} target="_blank" rel="noopener noreferrer" className="p-button font-bold">
                                    View in shipstation
                              </a>
                        </div>
                  }

                  {orderNumber && orderStatus !== 'awaiting_shipment' &&
                        <div>
                              <div> Order Status: {orderStatus} </div>

                              <div className="card flex justify-content-center">
                                    <a href={`https://ship4.shipstation.com/orders/all-orders-search-result?quickSearch=${orderNumber}`} target="_blank" rel="noopener noreferrer" className="p-button font-bold">
                                          View in shipstation
                                    </a>
                              </div>
                        </div>
                  }

           

                  <div className="card flex justify-content-center">
                        <Button label="Mark as shipped" icon="pi pi-check" onClick={markFrameAsShipped} />
                  </div>
                  <div style={{display:"flex"}}>
                        logout
                        <img onClick={()=> logout() } style={{width:"1.5rem"}} src={process.env.PUBLIC_URL + `/logout.png`} alt="logout" />
                  </div>
                  

            </div>
      }
      </>
      )
}

export default FrameModalFooter;
