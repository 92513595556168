import React from "react"
import { FrameTypesEnum } from "./models/frame-types-enum";

interface ITodaysTallyProps {
      frameType: FrameTypesEnum;
      premiereTotal: number;
      alloyTotal: number;
      ticketsTotal: number;
      premiereTicketTotal: number;
      alloyTicketTotal: number;
}


const TodaysTally = (props: ITodaysTallyProps) => {
      const [ isToggled, setIsToggled] = React.useState<boolean>(false)

      const rotate = isToggled ? "rotate(180deg)" : "rotate(0)"

      return (

            <div className="todays-tally-wrapper" >
                  { isToggled ?  
                        <>
                          <div className="title" style={{fontSize: "48px", color: "white", backgroundColor: "red", justifyContent: "space-between"}}>Tickets
                                    <div className="chevron-border" >
                                    <div className="chevron" style={{ transform: rotate, transition: "all 0.2s linear" }} onClick={()=>{ setIsToggled( curValue => !curValue)}}> 
                                           &#8963;

                                    </div>
                                    </div>
                        
                        </div>

                        </>
                        :
                        <>
                        <div className="title" style={{backgroundColor: "red", color: "white", display:"flex", justifyContent: "space-between"}}>Tickets
                        { props.premiereTotal === 0 ? 
                                    <div className="total zero-total">{props.ticketsTotal}</div>
                                    :
                                    <div className="total gtr-thn-zero-total">{props.ticketsTotal}</div>
                              }
                                    <div className="chevron-border" >
                                    <div className="chevron" style={{ transform: rotate, transition: "all 0.2s linear" }} onClick={()=>{ setIsToggled( curValue => !curValue)}}> 
                                    &#8963;

                                    </div>
                                    </div>
                        
                        </div>
                        <div className="title">Today's Tally:  
                   
                        </div>
                        <div className="dept">Premiere:
                              <div className="frame-category-tally">
                            
                                    <div className="ticket-tally" style={{ backgroundColor: props.frameType === "premiere" ? "lightgray" : "red" }}>{props.premiereTicketTotal ?? 0}</div>                              
                         
                              { props.premiereTotal === 0 ? 
                                    <div className="total zero-total">{props.premiereTotal}</div>
                                    :
                                    <div className="total gtr-thn-zero-total">{props.premiereTotal}</div>
                              }
                              </div>
                        </div>
                        <div className="dept">Alloy:
                        <div className="frame-category-tally">
                          
                                          <div className="ticket-tally" style={{ backgroundColor: props.frameType === "alloy" ? "lightgray" : "red" }}>{props.alloyTicketTotal ?? 0}</div>                              
                          
                              { props.alloyTotal === 0 ? 
                                    <div className="total zero-total">{props.alloyTotal}</div>
                                    :
                                    <div className="total gtr-thn-zero-total">{props.alloyTotal}</div>
                              }
                                                            </div>
                        </div>

                        <div className="dept">
                              { props.premiereTotal === 0 && props.alloyTotal === 0 ? 
                                    <div className="total zero-total">{props.alloyTotal  ?? 0}</div>
                                    :
                                    <div className="total gtr-thn-zero-total">{props.alloyTotal + props.premiereTotal}</div>
                              }
                        </div>
                        </>
                  }
            </div>

      )



}

export default TodaysTally;
