import React from "react"

export interface IColumnTotal {
      total: number;
      average: number;
}
export interface IStationColumnTotals  {
      completed: IColumnTotal;
      rejected: number;
      voided: number;
}

export interface IStationColumnTotalsProps  {
      stationTotals: IStationColumnTotals | undefined;

}

const StationColumnTotals = (props: IStationColumnTotalsProps) => {
      return (
            <div className='station-totals'>
                  <div className='station-total-entry'>
                        <div className="station-total-label">Completed</div>
                        { props.stationTotals?.completed?.total === undefined || props.stationTotals?.completed?.total === 0 ?
                                    <div className='station-total zero-total '>0</div>                                           
                                    :
                                    <div className='station-total gtr-thn-zero-total'>{props.stationTotals?.completed.total ?? 0}</div>                                                                   
                        }
                        
                  </div>
                  <div className='station-total-entry'>
                        <div className="station-total-label">Rej.</div>
                        { props.stationTotals?.rejected === undefined || props.stationTotals?.rejected === 0 ?
                                    <div className='station-total red zero-total'>0</div>                                           
                                    :
                                    <div className='station-total red gtr-thn-zero-total'>{props.stationTotals?.rejected ?? 0}</div>                                                                   
                        }

      </div>
            </div>
      );



}

export default StationColumnTotals;