import React from "react"
//import searchicon from './search-icon.png';
import { InputText } from 'primereact/inputtext';

interface IFloatingSearchBar {
      searchFramesFilter: (searchString: string) => void;
      searchTicketsFilter: (searchString: string) => void;
}


const FloatingSearchBar = (props: IFloatingSearchBar) => {

      const [toggle, setToggle] = React.useState<boolean>(false)
    //  const [searchString, setSearchFrameString] = React.useState<string>("");
      const inputRef = React.useRef<HTMLInputElement>(null);

      return (

            <div className="floating-search-bar" style={{display:"flex" }}>

      
                  <button
                        type="button"
                        onClick={() => setToggle(!toggle)}><img src={process.env.PUBLIC_URL + `/search-icon.png`} /></button>
                  {toggle && (

                  <div className="flex flex-row" style={{display:"flex", flexDirection:"column", marginLeft: "20px"}}>
                        <div style={{flexDirection:"column"}}>
                              <label htmlFor="search-frame">Search Frame: </label>
                              <InputText id="searchFrame" aria-describedby="search-frame"  onChange={(e) => {
                              //     setSearchFrameString(e.target.value);
                                    props.searchFramesFilter( e.target.value );
                              }}/>
                        </div>
                        <div style={{flexDirection:"column"}}>
                              <label htmlFor="search-ticket">Search Ticket: </label>
                              <InputText id="searchTicket" aria-describedby="search-ticket"  onChange={(e) => {
                              //        setSearchTicketString(e.target.value);
                                    props.searchTicketsFilter( e.target.value );
                              }}/>
                        </div>                              
                  </div>            
                  )}

            </div>

      )



}

export default FloatingSearchBar;
