export enum SOCKET_CHANNELS {
      CUTTING_PREMIERE = "cutting_premiere",
      CUTTING_ALLOY = "cutting_alloy",
      STATION = 'station',
      FIP = "fip",
      INVENTORY_PREMIERE = "inventory_premiere",
      MANUAL_RELOAD = "manual_reload",
      BOXING_PREP = "boxing_prep",
      MODIFY_TICKET_ON_FIP = 'MODIFY_TICKET_ON_FIP',
      TICKET_COUNT = "TICKET_COUNT"
}
