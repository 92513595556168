import React from "react"
import FIP from "../../models/fip";
import FrameHistoryTimeLine from "../../frame-history/frame-history-timeline";

interface IFrameModalContentsProps {
      frame: FIP
      removeFromColumn:  (stationId: number, frameSerialId: string) => void;
}
/**
 *  If the frame has a shipstation order on it, then link to shipstation
 *  If there is no shipstation link, then the 'ship' will just remove it from the FIP page
 *  and update it server side as 'shipped' so it moves to the shipped column. 
 */

const FrameModalContents = (props: IFrameModalContentsProps) => {

      return (

            <div className="frame-modal-wrapper">

                  <div className="frame-history-list">
                        <FrameHistoryTimeLine frame={props.frame}/>
                  </div>

            </div>
      )
}

export default FrameModalContents;
