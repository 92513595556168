import React from "react"
import FIP from "./models/fip";
import { FIPMessageTypes } from "./messages/fip-messages";
import Config from "./config";
import {motion} from 'framer-motion'
import shipstationLogo from './shipstation_logo.png';

interface IFIPTileInnerProps {
      fip: FIP;
      currentWork: any
      frameCategory: string

      addFrameToColumn?:  (stationId: number, frame: FIP ) => void;
      removeFromColumn?:  (stationId: number, frameSerialId: string) => void;
      onClick: React.MouseEventHandler<HTMLDivElement> 
}

const FIPTileInner = (props: IFIPTileInnerProps) => {
      function getAge(fip: FIP) {
   
            let made = Date.parse( fip.createdDateLocal );
            if ( fip.isReturned === true && fip.returnedDateLocal != null) {
                  made = Date.parse( fip.returnedDateLocal)
            }

            let now = new Date();
            let difference = now.getTime() - made;
            let daysOld = Math.ceil(difference / (1000 * 3600 * 24));
            let postfix = daysOld > 1 ? " days" : " day"
            return daysOld + postfix;
      }

      function getDurationAtStation(fip: FIP ) {

            let stationStart = Date.parse ( fip.curStationDateTime );
            let now  = Date.now();
            const difference = now - stationStart;
      
            // convert to minutes, hours or days whichever is more relevant
            if ( difference < 3600000) {  // an hour in ms
                  return (difference / 60000).toFixed(0) + 'm'
                  
            } else if ( difference >= 3600000 && difference < 86400000 ) {  // more than an hour and less than a day
                  return Math.floor( (difference / 3600000 )) + 'h'
            } else {
                  return Math.floor( difference / 86400000 ) +'d'
            }
      }

      function highlightEvent( messageTypeString?: string ) : [string, string] | ["#00000000", "#00000000"]{
      
            if (!messageTypeString) {
                  return  ["#00000000", "#00000000"];
            } 

            if ( messageTypeString === FIPMessageTypes.ADDED_FROM_OTHER_STATION.valueOf() || messageTypeString === FIPMessageTypes.FRAME_REJECTED_HERE.valueOf() ) {
                  return ["#FFD1DC", "#00000000"];  // pink to normalbackground
            } else if ( messageTypeString === FIPMessageTypes.FRAME_START_CANCELED.valueOf() ) {
                  return ["#FDFD96", "#00000000"];  // yellow to normalbackground
            } else if ( messageTypeString === FIPMessageTypes.FRAME_VOIDED.valueOf() ) {
                  return [ "#FF0000", "#00000000"];  // red to normalbackground
            } else if ( messageTypeString === FIPMessageTypes.FRAME_UNVOIDED.valueOf() ) {                  
                  return ["#FF964F", "#00000000"];  // orange to normalbackground
            } else if ( messageTypeString === FIPMessageTypes.FRAME_CREATED.valueOf() ) {
                  return ["#A7C7E7", "#ff000000"];  // blue to normalbackground
            }

            return ["#00000000", "#00000000"];

      }
      function ticketData() {
            return {
                  frameurl: `${Config.baseUrl }/img/moulding-label/img/${props.fip.image.replace( '_SUSTAINABLE', '')}.jpg`,
                  serialId: props.fip.serialId, 
                  age: 'todo',
                  framestyle: `${props.fip.name} ${props.fip.size}" - ${props.fip.year}`,
                  size: props.fip.size,
                  year: props.fip.year,
                  orderNumber: props.fip.ticket != null ? props.fip.ticket.orderNumber : "",
                  orderPriority:  props.fip.ticket != null ? ( props.fip.ticket.shippingPrioirtyDescription != null ? props.fip.ticket.shippingPrioirtyDescription : "") : "",
                  orderSource: props.fip.ticket != null ? ( props.fip.ticket.orderSourceDescription != null ? props.fip.ticket.orderSourceDescription : "" ) : "",
                  serviceLevel:  props.fip.ticket != null ? ( props.fip.ticket.shippingServiceLevelDescription != null ? props.fip.ticket.shippingServiceLevelDescription : "") : "",
                  shipByDate: props.fip.ticket != null ? ( props.fip.ticket.shipByDate  != null ? props.fip.ticket.shipByDate : "" ) : "",
            }
      }

      /*
      initial={{
                                    opacity: props.fip.workState === FIPMessageTypes.FRAME_VOIDED.valueOf() ? .9 : 0, 
                                    scale:  props.fip.workState === FIPMessageTypes.FRAME_VOIDED.valueOf() ? .9 : .75
                              }}
                              animate={{                                  
                                    opacity: 1, 
                                    scale: 1,
                                    transition: {
                                          duration: 5,
                                          delay: 1
                                    }
                              }}
      */

      return (
                  <div  onClick={props.onClick} className={`frame ${props.fip.ticket?.orderSourceId === "3" ? 'priority-fba' : props.fip.ticket?.shippingPriorityId === "1" ? "priority-low" : props.fip.ticket?.shippingPriorityId === "2" ? "priority-high" : "priority-none"} 
                                                ${props.fip.workState === FIPMessageTypes.FRAME_START_CANCELED.valueOf() ? "state-start-canceled" : ""} 
                                                ${props.fip.workState === FIPMessageTypes.ADDED_FROM_OTHER_STATION.valueOf() ? "state-added-from-other-station" : ""} 
                                                ${props.fip.workState === FIPMessageTypes.FRAME_START.valueOf() ? "state-started" : "" } 
                                                ${props.fip.workState === FIPMessageTypes.FRAME_END.valueOf()? "state-completed" : ""}`
                                          }
                   
                               >  

                              <motion.div style={{ display: "flex",  flexDirection: "row"}}
                                    //if UNVOIDING, show the background color immediately and the outer animation in fip-tile will cause it to fade in
                                    animate={{                                  
                                          backgroundColor: highlightEvent( props.fip.workState ),
                                          transition: {
                                                duration: 10,
                                                delay: props.fip.workState === FIPMessageTypes.FRAME_VOIDED.valueOf()  || props.fip.workState === FIPMessageTypes.FRAME_UNVOIDED.valueOf() ? 1 : 10
                                          }
                                    }}
                                    onAnimationComplete={definition => {
                                          // frame was voided, so no longer display on sceen after (inner) animation completes
                                          // one problem, is the frame could be registered as being in one column, then get scanned in another.  It will appear in the other on scan, but in the DB
                                          // it is still at the original station and shold be returned there.  
                                          if ( props.fip.workState && props.fip.workState === FIPMessageTypes.FRAME_START_CANCELED.valueOf() ) {
                                                if ( props.fip.returnToStationIfCanceled ) {
                                                      let stationId = props.fip.returnToStationIfCanceled!;
                                                      props.fip.returnToStationIfCanceled = undefined;
                                                      props.fip.workState = undefined;
                                                      props.addFrameToColumn!(stationId, props.fip )
                                                }                                              
                                                // remove the frame from the display in the column it is currently in (where it was canceled)
                                                if ( props.removeFromColumn ) {
                                                      props.removeFromColumn!(props.fip.curStationId, props.fip.serialId)
                                                } else {
                                                      console.error( " props.removeFromColumn not set properly ")
                                                }
                                          }
                                    }} >    
              
                        <div className={`frame-year-border year${props.fip.year} `}>
                        </div>
                        <div className="frame-image">
                              <div className={ `frame-style-color  size-${props.fip.size}` }>
                                    <img src={Config.baseUrl + '/img/moulding-label/img/' + props.fip.image.replace( '_SUSTAINABLE', '') + '.jpg' } alt="frame" />
                              </div>
                        </div>

                        <div className="frame-details" >
                              <div className='top-row'>
                                    <div className='serial' >
                                          {props.fip.serialId}
                                    </div>
                                    <div style={{ display:"flex", flexDirection:"column", }}>
                                          <div className='station-time'>
                                                ST { getDurationAtStation( props.fip)}
                                          </div>
                                          <div className='age'>
                                                { getAge(props.fip)}                                          
                                          </div>
                                    </div>
                              </div>
                              <div className='frame-style' >
                                    { props.frameCategory === "alloy" ? 
                                    <>
                                           { props.fip.profile} {props.fip.name} {props.fip.size} - {props.fip.year}</>
                                           : 
<>                                         {props.fip.name} {props.fip.size} - {props.fip.year}</>
                                    }
                              </div>

                              { (props.fip.isReturned || props.fip.isRecut ) && 
                                    <div className='other-properties' >
                                         { props.fip.isReturned === true && <div>{"RTN: " + props.fip.returnedDateLocal}</div> }
                                         { props.fip.isRecut === true && <div>{ "RCT " + props.fip.recutDateLocal}</div> }
                                    </div>
                              }

                              {props.fip.ticket?.orderNumber &&

                                    <div className='ticket'>
                                          <div className="ticket-info">
                                                <div>{props.fip.ticket?.orderNumber}</div>
                                                <div>{ props.fip.ticket?.shipByDate}</div>
                                                <div>{props.fip.ticket?.orderSourceDescription}</div>
                                                <div>{props.fip.ticket?.shippingServiceLevelDescription}</div>
                                          </div>
                                          <div>
                                                {props.fip.ticket && 
                                                      <>
                                                            { props.fip.ticket.ssOrderId && props.fip.ticket.ssOrderItemId && 
                                                                  // eslint-disable-next-line jsx-a11y/alt-text
                                                                  <img className="shipstion-logo" src={process.env.PUBLIC_URL + `/shipstation_logo.png`} alt="SS"/>
                                                            }
                                                      </>                                          
                                                }
                                          </div>
                                    </div>
                              }
                              {props.fip.workState && 
                                    <div className={ `work-state ${props.fip.workState.valueOf().toLowerCase()}` }>
                                          {props.fip.workState + " "} 
                                          {props.fip.workStartTime === undefined ? "" : new Date( props.fip.workStartTime! ).toLocaleTimeString() }
                                    </div>
                              }
                              {props.fip.userName && 
                                    <div> {props.fip.userName} </div>
                              }
                        </div>
                  </motion.div>
                  </div>
      );


}

export default FIPTileInner;