import React from "react"

interface IBoxingPrepTotals {
      inventoryTotal: number;
}


const BoxingPrepTotals = (props: IBoxingPrepTotals) => {

      return (

            <div className="boxing-prep-wrapper">
                  <div className="title">Boxing Prep</div>
                  <div className="sub-title">Inventory:
                        { props.inventoryTotal === 0 ? 
                              <div className="total zero-total">{props.inventoryTotal}</div>
                              :
                              <div className="total gtr-thn-zero-total">{props.inventoryTotal}</div>
                        }
                  </div>
            </div>
      )
}

export default BoxingPrepTotals;
