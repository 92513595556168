import React, { useEffect, useState } from "react"
import FIP from "../../models/fip";
import { Dialog } from "primereact/dialog";
import FIPTile2 from "../../fip-tile2";
import FrameModalFooter from "./frame-modal.footer";
import { FrameTypesEnum } from "../../models/frame-types-enum";
import FrameModalContents from "./fame-modal-contents";
import Config from "../../config";
import useFetch from "../../hooks/use-fetch";
import { isUint8Array } from "util/types";
import FrameModalHeader from "./frame-modal-header";

interface IFrameModalProps {
      visible: boolean
      fip: FIP      
      onClick: any;                                             
      removeFromColumn:  (stationId: number, frameSerialId: string) => void;
      getColumnPosition: ( stationId: number ) => number;
      addFrameToColumn: ( stationId: number, frame: FIP ) => void;
      frameCategory: FrameTypesEnum;
      removeWorkMessageFromFrame: ( stationId: number, frameSerialId: string ) => void;  
      closeModal: ( ) => void;
      isAuthenticated: boolean;
      setAuthenticated: any;
}

interface OrderNameLookupResponse {
      success: boolean;
      orderNumber?: string;
      orderStatus?: string;
      error?: string;
}
/**
 *  If the frame has a shipstation order on it, then link to shipstation
 *  If there is no shipstation link, then the 'ship' will just remove it from the FIP page
 *  and update it server side as 'shipped' so it moves to the shipped column. 
 */

const FrameModal = (props: IFrameModalProps) => {
      
      const [orderId, setOrderId] = useState<number | null>(null)
      const url = `${Config.baseUrl}/api/shipstation/order/lookup/${orderId}`;

      const [orderNumber, setOrderNumber] = useState<string | undefined>(undefined)
      const [orderStatus, setOrderStatus] = useState<string | undefined>(undefined)
      const { data, loading, error } = useFetch<OrderNameLookupResponse>(url ?? "", [], {});


      useEffect(() => {
            if (data && data.success === true) {
                  setOrderNumber(data.orderNumber!)
                  setOrderStatus(data.orderStatus!)
            }

      }, [data]);

      useEffect(() => {
            if (props.fip && props.fip.ticket && props.fip.ticket!.ssOrderId) {
                  setOrderId(props.fip.ticket!.ssOrderId!)
            }

      }, [props.fip]);


      return (
            <>
            <Dialog header="View Frame" visible={props.visible} style={{ width: '1000px' }} onHide={()=> props.closeModal() } 
                        footer={ <FrameModalFooter frame={props.fip} 
                                                   orderNumber={orderNumber} 
                                                   orderStatus={orderStatus} 
                                                   ticketId={props.fip.ticket!.ticketId!}
                                                   removeFromColumn={props.removeFromColumn} 
                                                   isAuthenticated={props.isAuthenticated} 
                                                   setAuthenticated={props.setAuthenticated} /> }>

                  <p className="m-0" style={{backgroundColor: "#e5e5e5"}}>
                        { props.fip && 
                        <>
                              <FIPTile2  key={props.fip.serialId } fip={props.fip} onClick={()=> null } currentWork={null} removeFromColumn={props.removeFromColumn} getColumnPosition={props.getColumnPosition} addFrameToColumn={props.addFrameToColumn } frameCategory={props.frameCategory} removeWorkMessageFromFrame={props.removeWorkMessageFromFrame}/>

                              <div className="order-shipment-status">
                              { orderStatus && 
                                          <>
                                          <div>Order Status:</div>
                                          <div>{orderStatus}</div>
                                          </>

                              }

                              {!orderStatus &&
                              
                                    <p>This is a manual ticket (not from shipstation)</p>

                              }
                                  </div>

                              <FrameModalContents frame={props.fip} removeFromColumn={props.removeFromColumn}></FrameModalContents>
                        </>
                        }
                  </p>

            </Dialog>
            </>
      )
}

export default FrameModal;


