
export enum BoxingPrepMessageTypes {
      /// these are basically a subset of the frame history events
      // but are also used for dipslay in the UI so should be readable      
      BOX_MADE = "BOX_MADE",
  //    BOX_CANCELED = "BOX_CANCELED", // not needed a box canceled will not have triggered a made event
      BOX_USED = "BOX_USED", // TODO is this needed or can it be driven off of boxing with FIP message?

}

export class BoxingPrepMessageBase {
      messageType: string
      constructor( messageType: BoxingPrepMessageTypes) {
            this.messageType = messageType.valueOf()
      }
}

export class BoxingPrepMessage extends BoxingPrepMessageBase {
      constructor( messageType : BoxingPrepMessageTypes ) {
            super( messageType )
      }
}