/*
socket.on 'connect',-> console.log 'connected'
socket.on 'reconnect',-> console.log 'reconnect'
socket.on 'connecting',-> console.log 'connecting'
socket.on 'reconnecting',-> console.log 'reconnecting'
socket.on 'connect_failed',-> console.log 'connect failed'
socket.on 'reconnect_failed',-> console.log 'reconnect failed'
socket.on 'close',-> console.log 'close'
socket.on 'disconnect',-> console.log 'disconnect'
*/

export enum ConnectionStatusEnum {
      connecting = "Connecting",
      connected = "Connected",
      disconnected = "Disconnected",      
      reconnected = "Reconnecting",
      connectFailed = "Connection Failed",
      reconnectFailed = "Reconnect Failed",
      close = "Closed",

}