
/*
let url = URL ( string: "\(Config.baseUrl())/api/scanner/history-event/\(qrCode)")!
let apiRequest = APIRequest( url: url )
let result: GetFrameHistoryResponse = try await  apiRequest.getAsync()
*/

import { FrameHistoryEntry } from './models/frame-history-event.model';

/*
interface IDailyActivityReportProps {
      userId: string;
      date: string;
}
*/

interface ITimeLineDateTime extends FrameHistoryEntry {


}

//(props: IDailyActivityReportProps) => {
const TimeLineDateTime = ( props: ITimeLineDateTime) => { 
 
      function formatDate( dateString: string){
            let date = new Date( props.date );
            return date.toLocaleDateString()
      }
      function formatTime( dateString: string){
            let date = new Date( props.date + " " + props.time );
            return date.toLocaleTimeString()
      }
      return (
            <div className='flex flex-row text-xl'>
                  { formatDate(props.date) } { formatTime(props.time)}
            </div>
      )
}

export default TimeLineDateTime;
