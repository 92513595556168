import React from "react"
import FIP from "./models/fip";
import { Slide, FadeOut, FadeIn, SlideOutRight, SlideInDown } from 'react-animated-components'
import FIPTileInner from "./fip-tile-inner";
import { FIPMessageTypes } from "./messages/fip-messages";

import wifiblue from './assets/wifi-blue.png';
import wifiorange from './assets/wifi-orange.png';
import wifigreen from './assets/wifi-green.png';
import { ConnectionStatusEnum } from "./models/connection-status-enum";

interface IConnectionStatus {
      connectionStatus: ConnectionStatusEnum;
      transport: string;
      connectionError: string;
      clientId: string; // for dev purposes
}


// Wait for the slide entrance to complete before exiting
/*
const SlideInAndFadeout = () => {
  const [didEnter, setDidEnter] = React.useState(false)
  const onDidEnter = () => setDidEnter(true)
  return (
    <FadeOut active={didEnter}>
      <SlideInDown onComplete={onDidEnter}>🥧</SlideInDown>
    </FadeOut>
  )
}
*/


const ConnectionIndicator = (props: IConnectionStatus) => {
      function renderState(props: IConnectionStatus ) {
            if( props.connectionStatus === ConnectionStatusEnum.connecting || props.connectionStatus === ConnectionStatusEnum.reconnected) {
                  return (
                        <>  
                              <div>{props.connectionStatus}</div>
                              <img src={wifiblue} alt="Logo" /> 
                              <div>{props.transport}</div>                              
                         </>
                  );
              } else if( props.connectionStatus === ConnectionStatusEnum.disconnected) {
                  return (
                        <>  
                              <div>{props.connectionStatus}</div>
                                    <img src={wifiorange} alt="Logo" /> 
                                    <div>{props.transport}</div>
                              <div>{props.connectionError}</div>
                        </>
                  );

              } else if( props.connectionStatus === ConnectionStatusEnum.connected) {
                  return (
                        <>  
                              <div>{props.connectionStatus}</div>
                              <img src={wifigreen} alt="Logo" /> 
                              <div style={{ color: props.transport === 'websocket' ? 'green' : 'red' }}>{props.transport}</div>
                           {/* only needed for dev purposes    <div>{props.clientId}</div> */}
                        </>
                  );
             } else if( props.connectionStatus === ConnectionStatusEnum.close) {
                  return (
                        <>  
                              <div>{props.connectionStatus}</div>
                              <img src={wifiorange} alt="Logo" /> 
                              <div>{props.transport}</div>
                         </>
                  );
              } else if(     
                              props.connectionStatus === ConnectionStatusEnum.reconnectFailed ||
                              props.connectionStatus === ConnectionStatusEnum.connectFailed
                        ) {
                  return (
                        <>  
                              <div>{props.connectionStatus}</div>
                              <img src={wifiorange} alt="Logo" /> 
                              <div>{props.transport}</div>
                              <div>{props.connectionError}</div>
                         </>
                  );
              }
               else {
                  return (
                        <div>  <img src={wifiorange} alt="Logo" /> unknown state</div>
                  );

               }

      }
          

      return (
            <div className="connection-indicator">
                  { renderState( props )}
            </div>
      );
}

export default ConnectionIndicator;