
export enum StationMessageTypes {
      STATION_START = "STATION_START",
      STATION_PAUSE = "STATION_PAUSE",
}

export class StationMessage  {

      constructor( messageType : StationMessageTypes, curStation: number, userName: string, userId: string, nextStation?: number ) {
            this.messageType = messageType
            this.curStation = curStation;
            this.userName = userName;
            this.userId  = userId;
            this.nextStation = nextStation;
            let now = new Date();
            
      }

      messageType: StationMessageTypes; //where the frame currently is
      curStation: number; //where the frame currently is
      userName: string;
      userId: string;
      nextStation?: number;

}