export enum StationsEnum {

      receiving = 1,
      inspection = 2,
      cutting = 3,
      assembly = 4,   //60
      boxingPrep = 5, // 71
      boxing = 6,
      warehouse = 7,
      shipping = 8,
      qualityControl = 9,
      boxingAlloy = 10,
      alloyCutting = 11,
      premiereInventory = 12,
      alloyInventory = 13,
      boring = 14,
      pinsAndMagnets = 15,
      milling = 16,
      premiereFullfillment = 17,
      alloyFullfillment = 18,
      finalPrep = 19,
      coloring = 20,

      // this is for tickets / frames after QC that can be shipped.  Not sure if this is a permanent station or temp so adding a high number so it doesn't interfere with sequential order if another station is added
      premiereAwaitingShipping = 90,

}

export namespace StationsEnum {

      export function toDisplayName(value: string): string {
            let displayName = "not found";
            let val = parseInt( value );
            switch (val) {
                  case StationsEnum.receiving:
                        displayName = "Receiving"
                        break;
                  case StationsEnum.inspection:
                        displayName = "Inspection"
                        break;
                  case StationsEnum.cutting:
                        displayName = "Premiere Cutting"
                        break;
                  case StationsEnum.assembly:
                        displayName = "Assembly"
                        break;
                  case StationsEnum.boxingPrep:
                        displayName = "Boxing Prep"
                        break;
                  case StationsEnum.boxing:
                        displayName = "Premiere Boxing"
                        break;
                  case StationsEnum.shipping:
                        displayName = "Shipping"
                        break;
                  case StationsEnum.qualityControl:
                        displayName = "Quality Control"
                        break;
                  case StationsEnum.boxingAlloy:
                        displayName = "Alloy Boxing"
                        break;
                  case StationsEnum.alloyCutting:
                        displayName = "Alloy Cutting"
                        break;
                  case StationsEnum.premiereInventory:
                        displayName = "Premiere Receiving"
                        break;
                  case StationsEnum.alloyInventory:
                        displayName = "Alloy Receiving"
                        break;
                  case StationsEnum.boring:
                        displayName = "Premiere Boring"
                        break;
                  case StationsEnum.pinsAndMagnets:
                        displayName = "Pins & Magnets"
                        break;
                  case StationsEnum.milling:
                        displayName = "Milling"
                        break;
                  case StationsEnum.premiereFullfillment:
                        displayName = "Premiere Fullfillment"
                        break;
                  case StationsEnum.alloyFullfillment:
                        displayName = "Alloy Fullfillment"
                        break;
                  case StationsEnum.finalPrep:
                        displayName = "Final Prep"
                        break;
                  case StationsEnum.coloring:
                        displayName = "Coloring"
                        break;
                  case StationsEnum.premiereAwaitingShipping:
                        displayName = "Premiere Awaiting Shipping"
                        break;

            }
            return displayName;
      }

}


