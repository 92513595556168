import React from "react"
import FIP from "./models/fip";
import { FIPMessageTypes } from "./messages/fip-messages";
import Config from "./config";
import { StationActivity } from "./models/station-activity";
import { StationMessageTypes } from "./messages/station-messages";

interface IStationActivity {
      user: StationActivity;
}

const StationUserTileInner = (props: StationActivity) => {

      return (

                  <div className={ `station-user ${ props.activity === StationMessageTypes.STATION_START ? 'active' : 'paused'} `}>
                        <div className={`user-name`} >
                              {props.userName} - { props.startTime}
                        </div>
                  </div>

      );


}

export default StationUserTileInner;