import { MessageBase } from "./message-base";

export enum InventoryMessageTypes   {
      ADD_BATCH_ITEM = "ADD_BATCH_ITEM",
      DELETE_BATCH_ITEM = "DELETE_BATCH_ITEM",   
      FINISHED_INVENTORY = "FINISHED_INVENTORY",
      RESET_INVENTORY = "RESET_INVENTORY",
}

export class InventoryUpdateMessage extends MessageBase {

      constructor(      
            messageType: InventoryMessageTypes, 
            frameSerialId: string, 
            stationId?: number 
      ) {
            super(messageType)
            this.frameSerialId = frameSerialId;
            this.stationId = stationId;
      }

      frameSerialId: string;
      stationId?: number;

}