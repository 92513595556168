import React, { useEffect, useState } from "react"
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import { Button } from "primereact/button";
import Config from "../config";



interface ILoginProps {
      visible: boolean;
      isAuthenticated: boolean;
      setAuthenticated: any;
      closeModal: () => void;
}
/**
 *  If the frame has a shipstation order on it, then link to shipstation
 *  If there is no shipstation link, then the 'ship' will just remove it from the FIP page
 *  and update it server side as 'shipped' so it moves to the shipped column. 
 */

const LoginModal = ( props: ILoginProps) => {

      const [userName, setUserName] = useState("")
      const [password, setPassword] = useState("")
      const [loginError, setLoginError] = useState("")

      function login() {
            setLoginError("");
        //    e.preventDefault();
            fetch (`${Config.baseUrl}/api/auth/login/web`, {
               method: 'POST',
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify({
                 username: userName,
                 password: password
              }),
          })
          .then((response) => response.json())
          .then((result) => {
              if(result.success === true){
                  props.setAuthenticated( true )
                  props.closeModal()
               } else {
                  setLoginError('Login failed. Please verify your information.');
               }
            });
      }

     return ( 
            <Dialog header="Login" visible={props.visible} style={{ width: '400px' }} onHide={()=> props.closeModal()} >
                  <p className="m-0">
                        <div className="login-modal">

                              <div className="flex flex-column gap-2" style={{ display: "flex", flexDirection: "column", padding: "20px", color: "white", backgroundColor: "#37587f", marginBottom: "20px"}}>

                                    <label htmlFor="login-username">Username: </label>

                                    <InputText id="login-user-name" aria-describedby="login-username" style={{marginBottom:"10px", width: "300px"}} onChange={(e) => {
                                          setUserName( e.target.value)
                                    }}/>

                                    <label htmlFor="login-password">Password: </label>
                                    <Password toggleMask id="login-password" aria-describedby="login-password"  style={{marginBottom:"10px", width: "300px"}} onChange={(e) => {
                                          setPassword( e.target.value )
                                    }}/>
                              </div>    
                              { loginError && 
                                    <div style={{color:"red"}}>
                                          {loginError}
                                    </div>
                              }        
                              <div>                               
                                    <Button label="Login" style={{marginRight:"20px"}} onClick={ ()=> login() }/> 
                                    <Button label="Cancel" color="gray" onClick={()=> props.closeModal() }/>
                              </div>
                        </div>
                  </p>
            </Dialog>
     )
      
}

export default LoginModal;

/*
           

*/
